<template>
  <div
    class="article"
    v-if="data"
    :style="{ backgroundColor: backgroundcolor }"
    :class="{ 'p-5': padding }"
  >
    <h1 class="font-weight-300 mb-4 text-center">
      {{ data.title }}
    </h1>
    <p
      class="font-size-15 wc-subtitle col-12 col-sm-12 col-md-8 col-lg-4 text-center"
    >
      {{ data.text }}
    </p>
    <p
      class="font-size-15 wc-subtitle text-center text-muted"
      v-if="showStatus"
    >
      {{ data.status }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    backgroundcolor: {
      type: String,
      default: "#161640",
    },
    padding: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.article {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 20px;
}
</style>