<template>
  <section class="section document-info">
    <div class="container">
      <div class="row ml-0 mr-0 document-info-padding">
        <div class="col-12 col-md-6 col-lg-6">
          <h3 class="text-dark-blue">{{ $t("simplyUploadDocument") }}</h3>
          <p class="mb-3">
            {{ $t("simplyUploadDocumentText") }}
          </p>
          <router-link to="/registrieren">
            <p
              class="text-dark-blue font-weight-bold"
              style="text-decoration: underline"
            >
              {{ $t("startNow") }}
            </p>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-6 order-first order-md-1">
          <div class="download-box">
            <font-awesome-icon :icon="faFileArrowUp" class="input-icon mb-4" />
            <h4 class="text-dark-blue">{{ $t("upload") }}</h4>
          </div>
        </div>
      </div>
      <div class="row ml-0 mr-0">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="download-info-box">
            <div class="vertical-line"></div>
            <ul class="font-size-14">
              <li>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="faCircleCheck"
                    class="text-blue mr-2"
                  />
                  <p>{{ $t("receivedDocument") }}</p>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="faCircleCheck"
                    class="text-blue mr-2"
                  />
                  <p>{{ $t("dataValidation") }}</p>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="faCircleCheck"
                    class="text-blue mr-2"
                  />
                  <p>{{ $t("calculationTax") }}</p>
                </div>
              </li>
              <li>
                <div class="d-flex align-items-center">
                  <font-awesome-icon
                    :icon="faCircleCheck"
                    class="text-blue mr-2"
                  />
                  <p>{{ $t("taxRefund") }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <h3 class="text-dark-blue">
            {{ $t("expertsHelm") }}
          </h3>
          <p class="mb-3">
            {{ $t("expertsHelmText") }}
          </p>
          <router-link to="/registrieren">
            <p
              class="text-dark-blue font-weight-bold"
              style="text-decoration: underline"
            >
              {{ $t("startNow") }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faFileArrowUp,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faFileArrowUp,
      faCircleCheck,
    };
  },
};
</script>

<style lang="scss">
.document-info {
  background-color: #57c6ff0d;

  .document-info-padding {
    margin-bottom: 80px;
  }

  .download-box {
    border: 1px solid #414141;
    padding: 40px 30px;
    border-radius: 20px;
    -webkit-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    -moz-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input-icon {
      height: 80px;
    }

    svg {
      color: #01295f;
    }
  }

  .download-info-box {
    background-color: #01295f;
    padding: 40px 20px;
    border-radius: 20px;
    -webkit-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    -moz-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    color: #fff;
    position: relative;
    z-index: 99;

    ul {
      margin-bottom: 0;
      li {
        list-style: none;

        svg {
          background-color: #fff;
          border-radius: 50%;
          height: 18px;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .vertical-line {
      border-left: 1px solid #fff;
      height: calc(100% - 100px);
      position: absolute;
      left: 68px;
      top: 48px;
      z-index: -1;
    }
  }

  @media screen and (max-width: 768px) {
    .download-info-box {
      margin-bottom: 3rem;

      ul {
        padding-left: 0px !important;
      }
    }

    .vertical-line {
      height: calc(100% - 130px) !important;
      top: 74px !important;
      left: 28px !important;
    }
  }
}
</style>