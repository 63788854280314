<template>
  <section class="section price-info-page" id="price">
    <div class="container">
      <div class="price-information col-12">
        <div class="row align-items-center mb-4">
          <div class="col-12 col-md-6 col-lg-6">
            <h5>{{ $t("flinktaxCosts") }}</h5>
            <h5 class="text-blue">{{ $t("simpleCreateTax") }}</h5>
            <p class="text-muted full-service">{{ $t("fullService") }}</p>
          </div>
          <div class="col-12 col-md-6 col-lg-6 d-flex justify-content-end">
            <div class="price-information-button">
              {{ $t("minimumTax") }}
            </div>
          </div>
        </div>
        <div class="price-info-list">
          <ul v-for="(item, index) in priceInformationData" :key="index">
            <li class="d-flex align-items-center">
              <font-awesome-icon
                :icon="faCircleCheck"
                class="text-blue font-size-24 mr-2"
              />
              <p>{{ $t(item.text) }}</p>
            </li>
          </ul>
        </div>
        <router-link to="/registrieren">
          <button class="blue-button" type="button">
            {{ $t("startNow") }}
          </button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { priceInformationData } from "../../data/PriceInformationData";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faCircleCheck,
      priceInformationData,
    };
  },
};
</script>

<style lang="scss">
.price-info-page {
  background-color: #57c6ff0d;
  .price-info-list {
    ul {
      padding: 0;
      li {
        list-style: none;
      }
    }
  }
  .price-information-button {
    background-color: #57c6ff;
    border: none;
    padding: 10px 30px;
    border-radius: 20px;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    .price-information-button {
      text-align: center;
    }
    .full-service {
      margin-bottom: 0.5rem;
    }
  }
}
</style>