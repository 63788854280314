<template>
  <div>
    <div class="blog-box mb-4 mb-lg-0">
      <div class="blog-box-icon">
        <img :src="data.icon" alt="icon" />
      </div>
      <ul
        class="list-inline text-muted text-uppercase font-size-15 font-weight-medium mt-3 mb-2"
      ></ul>
      <p class="text-dark font-weight-bold h6 mt-3">{{ $t(data.title) }}</p>
      <p class="text-muted font-size-15 text-center">
        {{ $t(data.text) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss">
.blog-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .blog-box-icon {
    img {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #57c6ff;
    }
  }
}
</style>