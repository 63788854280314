export const priceInformationData = [
    {
        text: 'freeDocumentSystem'
    },
    {
        text: 'expertTeam'
    },
    {
        text: 'simpleTime'
    },
    {
        text: 'maximumTax'
    }
]