<template>
  <!-- Hero Start -->
  <section class="hero-1-bg" id="home">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center col-12">
        <div class="col-12 col-md-6 col-lg-6">
          <h1 class="hero-1-title mb-4">
            <span class="hero-1-title-blue">{{ $t("end") }}</span>
            {{ $t("endText") }}
          </h1>
          <div class="mb-5 mb-lg-0">
            <p class="mb-5 font-size-20 hero-sub-text">
              {{ $t("bannerText") }}
            </p>
            <router-link to="/registrieren">
              <button type="submit" class="blue-button">
                {{ $t("startButton") }}
              </button>
            </router-link>
          </div>
        </div>
        <div
          class="col-12 col-md-6 col-lg-6 hero-1-bg-img d-none d-md-block d-lg-block"
        >
          <div class="mt-5 mt-lg-0">
            <img
              src="@/assets/img/image-1.png"
              alt
              class="img-fluid d-block mx-auto"
              @mouseleave="mouseLeaveInfo"
            />
          </div>
          <div class="hero-1-bg-img-content">
            <div>
              {{ $t("dashboardInfo") }}
              <font-awesome-icon
                :icon="faCircleInfo"
                @mousemove="mouseMoveInfo"
              />
            </div>
          </div>
          <div class="hero-1-bg-img-content-info" v-if="showInfo">
            <a
              href="https://www.destatis.de/DE/Themen/Staat/Steuern/Lohnsteuer-Einkommensteuer/im-fokus-steuererklaerung.html"
              target="_blank"
              class="text-dark-blue font-weight-bold font-size-12"
              >{{ $t("sourceText") }}</a
            >
            <p class="font-size-12">{{ $t("notTaxReturn") }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Hero End -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faCircleInfo,
      showInfo: false,
    };
  },
  methods: {
    mouseMoveInfo() {
      this.showInfo = true;
    },
    mouseLeaveInfo() {
      this.showInfo = false;
    },
  },
};
</script>

<style>
</style>