<template>
  <section class="section confirm-information">
    <div class="container">
      <div class="row col-12">
        <div class="col-12 col-md-6 col-lg-6">
          <h3 class="text-dark-blue">{{ $t("confirmTax") }}</h3>
          <p class="mb-3">
            {{ $t("confirmTaxText") }}
          </p>
          <router-link to="/registrieren">
            <p
              class="text-dark-blue font-weight-bold"
              style="text-decoration: underline"
            >
              {{ $t("startNow") }}
            </p>
          </router-link>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="download-info-box text-center">
            <h4>{{ $t("taxRefundExpert") }}</h4>
            <h2>1.095 €</h2>
            <div class="confirm-information-button w-50">
              {{ $t("reviewConfirm") }}
            </div>
          </div>
          <img
            :src="require(`../../assets/img/phone-${$i18n.locale}.png`)"
            class="box-img d-none d-lg-block"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.confirm-information {
  .download-info-box {
    background-color: #01295f;
    padding: 40px 20px;
    border-radius: 20px;
    -webkit-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    -moz-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .box-img {
    position: relative;
    left: -145px;
    top: 120px;
    height: 360px;
    margin-bottom: 40px;
  }

  .confirm-information-button {
    background-color: #57c6ff;
    border: none;
    padding: 10px 30px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    .download-info-box {
      margin-top: 3rem !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .download-info-box {
      position: inherit;
    }
  }
}
</style>