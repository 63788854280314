<template>
  <div class="features">
    <Slider :data="contents" showStatus />
  </div>
</template>

<script>
import Slider from "@/components/base/Slider.vue";

export default {
  components: {
    Slider,
  },
  data() {
    return {
      contents: [
        {
          title: "„Kundenmeinung. Klicken und Text hinzuzufügen“",
          text: "Mark Schröder",
          status: "Firmenname",
        },
        {
          title: "„Kundenmeinung. Klicken und Text hinzuzufügen“",
          text: "Gabriella Kuntze",
          status: "Firmenname",
        },
        {
          title: "„Kundenmeinung. Klicken und Text hinzuzufügen“",
          text: "Helene Schenck",
          status: "Firmenname",
        },
      ],
    };
  },
};
</script>

<style>
</style>