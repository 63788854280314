<template>
  <b-carousel
    id="carousel-no-animation"
    style="text-shadow: 0px 0px 2px #000"
    no-animation
    indicators
    img-width="1024"
    img-height="480"
  >
    <b-carousel-slide v-for="(item, index) in data" :key="index">
      <template #img>
        <ContentCard :data="item" :showStatus="showStatus" />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import Article from "../dashboard/Article.vue";
import ContentCard from "./ContentCard.vue";

export default {
  components: {
    Article,
    ContentCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.carousel-indicators {
  li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>