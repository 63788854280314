const icon1 = require('../assets/img/icon/info-icon-1.png')
const icon2 = require('../assets/img/icon/info-icon-2.png')
const icon3 = require('../assets/img/icon/info-icon-3.png')
const icon4 = require('../assets/img/icon/info-icon-4.png')


export const companyInformations = [

    {
        icon: icon1,
        title: 'createProfile',
        text: 'createProfileText'
    },
    {
        icon: icon2,
        title: 'answerQuestion',
        text: 'answerQuestionText'
    },
    {
        icon: icon4,
        title: 'uploadDocuments',
        text: 'uploadDocumentsText'
    },
    {
        icon: icon3,
        title: 'relax',
        text: 'relaxText'
    },

]