<template>
  <ContentCard :data="content" />
</template>

<script>
import ContentCard from "../base/ContentCard.vue";
export default {
  components: { ContentCard },
  data() {
    return {
      content: {
        title: "Dein Steuerassistent mit Köpfchen",
        text: "Flinktax ist mehr als eine App, die automatisch Ihre Steuer berechnet. Wir denken proaktiv mit und streben danach, Ihnen die maximale Steuerrückerstattung zu sichern.",
      },
    };
  },
};
</script>

<style lang="scss">
.article {
  background-color: #161640;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 20px;
}
</style>