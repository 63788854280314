<template>
  <!-- Why Choose Us Start -->
  <section class="section">
    <div class="container">
      <div class="row align-items-center col-12 services-page">
        <div class="col-lg-4">
          <div class="mb-4 mb-lg-0 title-box">
            <h1 class="text-blue mb-5 font-weight-300">
              {{ $t("whyFlinktax") }}
            </h1>
            <h1 class="font-weight-300">{{ $t("advantagesGlance") }}</h1>
          </div>
        </div>
        <div class="col-lg-8 service-card">
          <div class="row">
            <div class="col-md-6">
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <font-awesome-icon :icon="faStar" />
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ $t("clarityTransparency") }}
                </h5>
                <p class="font-size-15 wc-subtitle">
                  {{ $t("clarityTransparencyText") }}
                </p>
              </div>
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <font-awesome-icon :icon="faUnlock" />
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ $t("safeReliable") }}
                </h5>
                <p class="font-size-15 wc-subtitle">
                  {{ $t("safeReliableText") }}
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <font-awesome-icon :icon="faClock" />
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ $t("timeSaving") }}
                </h5>
                <p class="font-size-15 wc-subtitle">
                  {{ $t("timeSavingText") }}
                </p>
              </div>
              <div class="wc-box rounded text-center wc-box-primary p-4">
                <div class="wc-box-icon">
                  <font-awesome-icon :icon="faCircleStop" />
                </div>
                <h5 class="font-weight-bold mb-2 wc-title mt-4">
                  {{ $t("indivudal") }}
                </h5>
                <p class="font-size-15 wc-subtitle">
                  {{ $t("indivudalText") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Why Choose Us End -->
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faStar,
  faClock,
  faUnlock,
  faCircleStop,
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faStar,
      faClock,
      faUnlock,
      faCircleStop,
    };
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
  .services-page {
    padding-right: 0px !important;

    .service-card {
      padding-right: 0px !important;
    }
  }
}
</style>