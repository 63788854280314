<template>
  <!-- Blog start -->
  <section class="section" id="howWorks">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-5">
            <p class="font-size-18 text-blue">{{ $t("howWorks") }}</p>
            <h3 class="title mb-5">{{ $t("taxReturn") }}</h3>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div
          class="col-lg-3"
          v-for="(item, index) in companyInformations"
          :key="index"
        >
          <Card :data="item" />
        </div>
      </div>
    </div>
  </section>
  <!-- Blog End -->
</template>

<script>
import Card from "@/components/base/Card.vue";
import { companyInformations } from "../../data/CompanyInformations";
import {
  faCircle,
  faArrowUpFromBracket,
  faCheck,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCircle, faArrowUpFromBracket, faCheck, faSquare);

export default {
  components: {
    Card,
  },
  data() {
    return {
      companyInformations,
      faCircle,
      faArrowUpFromBracket,
      faCheck,
      faSquare,
    };
  },
};
</script>

<style>
</style>