<template>
  <div>
    <Navbar />
    <div v-scroll-spy>
      <PublicitySection />
      <Information />
      <PriceInformation />
      <ExpertHelpInformation />
      <DocumentInformation />
      <ConfirmInformationVue />
      <Services />
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/shared/Navbar";
import Services from "@/components/dashboard/Services";
import PublicitySection from "@/components/dashboard/PublicitySection";
import Information from "@/components/dashboard/Information.vue";
import ArticleComp from "../components/dashboard/Article.vue";
import Features from "@/components/dashboard/Features.vue";
import ContentCard from "../components/base/ContentCard.vue";
import Card from "@/components/base/Card";
import PriceInformation from "../components/dashboard/PriceInformation.vue";
import ExpertHelpInformation from "../components/dashboard/ExpertHelpInformation.vue";
import DocumentInformation from "../components/dashboard/DocumentInformation.vue";
import ConfirmInformationVue from "../components/dashboard/ConfirmInformation.vue";
import Footer from "@/components/shared/Footer";
import { companyInformations } from "../data/CompanyInformations";

/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Services,
    Features,
    Card,
    ContentCard,
    Footer,
    PublicitySection,
    Information,
    ArticleComp,
    ContentCard,
    PriceInformation,
    ExpertHelpInformation,
    DocumentInformation,
    ConfirmInformationVue,
  },
  data() {
    return {
      companyInformations,
      content: {
        title: "",
        text: "KUNDENMEINUNGEN",
      },
    };
  },
  beforeRouteEnter(){
    // if(!location.hostname.includes("192.168") && !location.hostname.includes('localhost'))
    location.href="https://flinktax.de"
  },
};
</script>
