<template>
  <section class="section" id="about">
    <div class="container">
      <div class="col-12 col-lg-8 mb-5">
        <h3 class="text-dark-blue">{{ $t("howExpertAssistance") }}</h3>
        <p class="mb-3">
          {{ $t("howExpertAssistanceText") }}
        </p>
        <router-link to="/registrieren">
          <p
            class="text-dark-blue font-weight-bold"
            style="text-decoration: underline"
          >
            {{ $t("startNow") }}
          </p>
        </router-link>
      </div>
      <div class="row ml-0 mr-0 align-items-center">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="question-select-box">
            <h3 class="text-dark-blue">{{ $t("marriedSince") }}</h3>
            <div class="question-select-box-text">
              {{ $t("date") }}
              <font-awesome-icon :icon="faAngleDown" class="input-icon" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <h3 class="text-dark-blue">{{ $t("clearSimpleQuestions") }}</h3>
          <p class="mb-3">
            {{ $t("clearSimpleQuestionsText") }}
          </p>
          <router-link to="/registrieren">
            <p
              class="text-dark-blue font-weight-bold"
              style="text-decoration: underline"
            >
              {{ $t("startNow") }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faAngleDown,
    };
  },
};
</script>

<style lang="scss">
.question-select-box {
  background-color: #cff1fe;
  padding: 40px 30px;
  border-radius: 20px;
  -webkit-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
  -moz-box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);
  box-shadow: 5px 4px 0px -1px rgba(214, 214, 214, 1);

  .question-select-box-text {
    border: 1px solid #01295f;
    border-radius: 20px;
    background-color: transparent;
    padding: 6px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .question-select-box {
    margin-bottom: 3rem;
  }
}
</style>